import { Event } from "../../Event";
import { Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { requiredColumns } from "../../../utilities/situations/situationsFunctions";
import { NotSeeEvents } from "../../../utils/isNotSee";


const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0, itens: number) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
        valorDeDescontoUnitario: (valorTotal / itens)
    };
};


export class PsmRelationProductBasePriceEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        var dadosPsm = this.values;

        this.setState("loadings", [
            "psm_relation_product_value_all",
            "psm_relation_product_value_uniti"
        ]);

        if (value == 0 || value == null) {

            console.log('entrou no if')
            this.setFieldValue("psm_relation_product_value_all", 0.00000001); //0
            this.setFieldValue("psm_relation_product_value_uniti", 0.00000001);
            this.setFieldValue("psm_relation_product_discount_value_all", 0.00000001)
            // console.log({ dadosPsm })

        }

        if (value > 0 && dadosPsm?.psm_relation_product_quantity > 0) {

            this.setFieldValue('psm_relation_product_value_all', value * dadosPsm?.psm_relation_product_quantity)
            this.setFieldValue('psm_relation_product_value_uniti', value)
        }
        this.setState("loadings", []);
        // console.log({ dadosPsm })
    }
}


export class PsmRelationProductQuantityEvents extends Event {
    public onInit = () => {
        // console.log("Helooooou")
    }
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        var dadosPsm = this.globalProps.values;
        if (value) {
            this.setState("loadings", [
                "psm_relation_product_value_all"
            ]);
            let sum: number
            if ((dadosPsm?.psm_relation_product_margin !== undefined) && (dadosPsm?.psm_relation_product_margin !== 0)) {
                sum = (((dadosPsm?.psm_relation_product_margin / 100) * dadosPsm?.psm_relation_product_base_price) + dadosPsm?.psm_relation_product_base_price) * value
                this.setFieldValue("psm_relation_product_value_all", sum);
                this.setFieldValue("psm_relation_product_value_uniti", ((dadosPsm?.psm_relation_product_margin / 100) * dadosPsm?.psm_relation_product_base_price) + dadosPsm?.psm_relation_product_base_price)
            } else {
                if ((dadosPsm.psm_relation_product_value_uniti !== 0) && (dadosPsm.psm_relation_product_value_uniti !== undefined)) {
                    sum = value * dadosPsm.psm_relation_product_value_uniti;
                    this.setFieldValue("psm_relation_product_value_all", sum);
                }
            }
            this.setState("loadings", []);
        }
    };
}

export class PsmRelationProductMarginEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        if (['0', '', undefined, null].includes(value)) {
            this.setFieldValue('psm_relation_product_margin', 0)
        }
        if (this.globalProps.match.params.crud !== "dibis") {
            let dadosPsm = this.values;
            if (value) {
                if ((![undefined, null, 0].includes(dadosPsm?.psm_relation_product_base_price)) && (![undefined, null, 0].includes(dadosPsm?.psm_relation_product_quantity))) {
                    let sum: number
                    sum = (((value / 100) * dadosPsm?.psm_relation_product_base_price) + dadosPsm?.psm_relation_product_base_price) * dadosPsm?.psm_relation_product_quantity;
                    // console.log()
                    this.setFieldValue("psm_relation_product_value_all", sum);
                    this.setFieldValue("psm_relation_product_value_uniti", ((value / 100) * dadosPsm?.psm_relation_product_base_price) + dadosPsm?.psm_relation_product_base_price);
                }
            }
        }

    };
}

export class PsmRelationProductValueUnitiEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        let dadosPsm = this.values;
        if (value) {
            this.setState("loadings", [
                "psm_relation_product_value_all"
            ]);
            if (![undefined, 0].includes(dadosPsm.psm_relation_product_quantity)) {
                var sum: number = value * dadosPsm.psm_relation_product_quantity;
                this.setFieldValue("psm_relation_product_value_all", sum)
                // console.log("Valor do sum ::>", sum)
            }
            this.setState("loadings", []);
        }
    };
}

export class PsmRelationProductDiscountEvents extends Event {
    public onBlur = (value: any) => {
        this.setState("loadings", [
            "psm_relation_product_discount_value_all",
            "psm_relation_product_discount_percent",
            "psm_relation_product_value_all"
        ])
        if (value > 0) {
            var dadosPsm = this.globalState.formValues;
            var desconto = parseFloat(value);
            var inicial = dadosPsm.psm_relation_product_quantity * dadosPsm.psm_relation_product_value_uniti;

            var resultado = calculaValorTotal(inicial, 0, desconto, dadosPsm.psm_relation_product_quantity);
            // console.log(resultado);
            if (resultado.valorTotal > 0) {
                this.setFieldValue("psm_relation_product_discount_value_all", resultado.valorTotal);
                this.setFieldValue("psm_relation_product_discount_percent", resultado.percentual);
                this.setFieldValue('psm_relation_product_value_discount_uniti', resultado.valorDeDescontoUnitario)
                // this.setFieldValue("psm_discount", value.toFixed(2));
            } else {
                Notification.error({
                    title: "Valor do desconto inválido",
                });
                this.setFieldValue("psm_relation_product_discount_percent", 0);
                this.setFieldValue("psm_relation_product_discount", 0);
                this.setFieldValue("psm_relation_product_value_all", this.globalState.formValues.psm_relation_product_value_all);
            }
        }
        if ([null, 0].includes(value)) {
            this.setFieldValue("psm_relation_product_discount_value_all", 0);
            this.setFieldValue("psm_relation_product_discount_percent", 0);
            this.setFieldValue("psm_relation_product_value_discount_uniti", 0);
        }
        this.setState("loadings", []);
    };
}

export class PsmRelationProductDiscountPercentEvents extends Event {
    public onBlur = (value: any) => {
        if (value > 0) {
            var dadosPsm = this.globalState.formValues;
            var porcentagem = parseFloat(value);
            var inicial = dadosPsm.psm_relation_product_quantity * dadosPsm.psm_relation_product_value_uniti;
            var desconto = parseFloat(this.globalState.formValues.psm_product_registration_discount ?? "0");
            var resultado = calculaValorTotal(inicial, porcentagem, 0, dadosPsm.psm_relation_product_quantity);
            if (resultado.valorTotal > 0) {
                this.setFieldValue("psm_relation_product_discount_value_all", resultado.valorTotal);
                this.setFieldValue("psm_relation_product_discount", resultado.desconto);
                this.setFieldValue('psm_relation_product_value_discount_uniti', resultado.valorDeDescontoUnitario)
                // this.setFieldValue("psm_discount_percentage", value.toFixed(2));
            } else {
                Notification.error({
                    title: "Percentual de desconto inválido",
                });
                this.setFieldValue("psm_relation_product_discount_percent", 0);
                this.setFieldValue("psm_relation_product_discount", 0);
                this.setFieldValue("psm_relation_product_value_all", this.globalState.formValues.psm_relation_product_value_all);
            }
        }
        if ([null, 0].includes(value)) {
            this.setFieldValue("psm_relation_product_discount_value_all", 0);
            this.setFieldValue("psm_relation_product_discount", 0);
            this.setFieldValue("psm_relation_product_value_discount_uniti", 0);
        }
    };
}

export class PsmRelationProductFkSelectIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        console.log('a ::::> ', setField)
        if (value) {
            this.setState("loadings", [
                "psm_relation_product_intern_reference",
                "psm_relation_product_product_id",
                "psm_relation_product_item_description",
                "psm_relation_product_base_price",
                "psm_relation_product_politic",
                "psm_relation_product_margin",
                "psm_relation_product_value_uniti",
                "psm_relation_product_fk_fiscal_classification_id",
                "psm_relation_product_fk_measure_unit_id",
                "psm_relation_product_manufacture_code",
                "psm_relation_product_fk_select_id",
                "psm_relation_product_origin_name"

            ]);

            axios.get("/api/v1/products/" + value.valueFk).then(async (res: any) => {
                var margin = res.data.item.product_ideal_margin;
                var marginDiscount = margin !== undefined ? (margin / 1000) * 300 + (margin / 100 * 100) : 0;


                if (res && res.data && res.data.item) {
                    this.setFieldValue("psm_relation_product_intern_reference", res.data.item.product_intern_reference);
                    this.setFieldValue("psm_relation_product_product_id", JSON.stringify(res.data.item.product_id));
                    this.setFieldValue("psm_relation_product_item_description", res.data.item.product_description);
                    this.setFieldValue("psm_relation_product_fk_measure_unit_id", res.data.item.product_fk_measure_unit_id);
                    this.setFieldValue("psm_relation_product_fk_select_id", value.valueFk)
                    if (value.isExchangeValue) {
                        this.setFieldValue("psm_relation_product_base_price", res.data.item.product_price_min);
                        this.setFieldValue("psm_relation_product_politic", margin);
                        this.setFieldValue("psm_relation_product_margin", marginDiscount);
                        this.setFieldValue("psm_relation_product_value_uniti", res.data.item.product_price_min);
                    }
                    if ((res.data?.item.product_fk_fiscal_classification_id !== null) && (res.data?.item.product_fk_fiscal_classification_id !== undefined)) {
                        this.setFieldValue("psm_relation_product_fk_fiscal_classification_id", res.data?.item.product_fk_fiscal_classification_id.toString());
                    }
                    if (![null, undefined].includes(res.data.item?.product_manufacturer_code)) {
                        this.setFieldValue("psm_relation_product_manufacture_code", res.data.item?.product_manufacturer_code);
                    }
                    this.setState("loadings", ["psm_relation_product_fk_fiscal_classification_id",]);
                    await axios
                        .get(`/api/v1/fiscal-classification/${res.data?.item.product_fk_fiscal_classification_id}`)
                        .then((res) => {
                            this.setFieldValue("psm_relation_product_fk_fiscal_classification_id", { value: res.data?.item.fiscal_classification_id, label: `${res.data.item.fiscal_classification_abbreviation} - ${res.data.item.fiscal_classification_name}` });
                        })
                    this.setState("loadings", []);


                    // this.setState("loadings",["psm_relation_product_fk_fiscal_classification_id"])
                    // this.setFieldValue("psm_relation_product_fk_fiscal_classification_id", res.data.item.product_fk_fiscal_classification_id);
                    // this.setState("loadings",["psm_relation_product_fk_fiscal_classification_id"])

                }

            })
                .catch((e: any) => { })
                .finally(() => {
                    this.setState("loadings", []);
                });
        }
    }
}



export class PsmRelationProductEvents extends Event {


    public onLoad = async (value: any, setField: any, setState: any) => {
        const fieldColumns = this.globalProps.form;
        const situationIdDibi = this?.globalProps?.parentValues?.psm_status_fk_dibi_situation_id;



        if (this.globalProps.match.params.crud == "commercials") {
            const dadosPsm = this.globalProps.values;



            let infosRoles: any


            await axios
                .get("/api/v1/me")
                .then((subRes) => {
                    var subData = subRes.data;
                    infosRoles = subData.user.role.role_permissions

                    // if (subData) {
                    //     this.setFieldValue(ƒ"psm_user", subData.user.user_name);ƒ
                    // }
                })

            let isValidNewObservation = true;

            for (const t in infosRoles) {
                if (infosRoles[t].role_permission_value == 'commercials_viewfields') {

                    isValidNewObservation = false
                    this.setState("loadings", [
                        "psm_relation_product_customer_order",
                        "psm_relation_product_iten_client",
                        "psm_relation_product_intern_reference",
                        "psm_relation_product_product_id",
                        "psm_relation_product_item_description",
                        "psm_relation_product_registered",
                        "psm_relation_product_recomended",
                        "psm_relation_product_sales_history",
                        "psm_relation_product_show_budget",
                        "psm_relation_product_quantity",
                        "psm_relation_product_quant_avaliabler",
                        "psm_relation_product_obs",
                        "psm_relation_product_fk_measure_unit_id",
                        "psm_relation_product_deadline",
                        "psm_relation_product_revenues_date",
                        "psm_relation_product_base_price",
                        "psm_relation_product_politic",
                        "psm_relation_product_margin",
                        "psm_relation_product_value_uniti",
                        "psm_relation_product_discount",
                        "psm_relation_product_discount_percent",
                        "psm_relation_product_value_all",
                        "psm_relation_product_div",
                        "psm_relation_product_ncm",
                        "psm_relation_product_fk_fiscal_classification_id",
                        "psm_relation_product_ipi",
                        "psm_relation_product_icms_anticipation",
                        "psm_relation_product_icms",
                        "psm_relation_product_icms_interstate",
                        "psm_relation_product_icms_sell",
                        "psm_relation_product_st",
                        "psm_relation_product_div2",
                        "psm_relation_product_instal",
                        "psm_relation_product_supplementary_description",
                        "psm_relation_product_discount_value_all",
                        "psm_relation_product_origin_name",
                        "psm_relation_product_fk_select_id",
                        "psm_relation_product_reproving",
                        "psm_relation_product_value_discount_uniti",
                        "psm_relation_product_manufacture_code",
                        "psm_relation_product__id",
                        "psm_relation_product_bound",
                        "psm_relation_product_analysi_id",
                        "psm_relation_product_requisition_number",
                        "psm_relation_product_observation_commercial"
                    ])

                    const notSee: any = await NotSeeEvents.notSee('psm_relation_products')

                    for (const field of notSee) {
                        this.setField(field.name, field.properties);
                    }

                    //fix tamanhos

                    this.setField("psm_relation_product_origin_name", {
                        width: 4
                    })

                    this.setField("psm_relation_product_item_description", {
                        width: 4,
                        hidden: false,
                        readonly: true
                    })

                    this.setField("psm_relation_product_intern_reference", {
                        width: 4
                    })

                    this.setField("psm_relation_product_registered", {
                        width: 1,
                        readonly: true,
                        disabled: true
                    })

                    this.setField("psm_relation_product_value_uniti", {
                        readonly: true,
                        disabled: true
                    })

                    this.setField("psm_relation_product_instal", {
                        width: 1
                    })

                    this.setField("psm_relation_product_reproving", {
                        width: 1
                    })

                    this.setField("psm_relation_product_quantity", {
                        width: 3,
                        readonly: true,
                        disabled: true,
                    })

                    this.setField("psm_relation_product_value_all", {
                        width: 3
                    })

                    setTimeout(() => {
                        this.setState("loadings", [])
                    }, 3000)
                    // setInterval(verificarURLYAtivarMostrarSomenteColunas, 500);

                    // console.log("this ::>", this)
                }
            }

            if (isValidNewObservation == true) {
                this.setField("psm_relation_product_observation_commercial", { hidden: false })
            }


            if (this.globalProps.parentValues.psm_status_fk_situation_id >= 5 && this.globalProps.values.psm_relation_product_id) {
                const matchParams = this.globalProps.match.params;
                if (this?.globalProps?.isEdit !== null) {
                    // this.setState("loadings", ['psm_relation_product_fk_select_id', 'psm_relation_product_item_description', 'psm_relation_product_registered',
                    //     'psm_relation_product_reproving', 'psm_relation_product_quantity', 'psm_relation_product_fk_measure_unit_id'
                    // ]);
                    await axios.get("/api/v1/check-item/product", {
                        params: {
                            crud: matchParams?.crud,
                            idPsm: matchParams?.id,
                            idProduct: dadosPsm?.psm_relation_product_id,
                            tableName: this?.globalProps.name
                        }
                    }).then((response: any) => {
                        if (response.data.status == 200) {
                            Notification.info({
                                title: 'Permitido Alteração',
                                description: response.data.message
                            })

                            this.setField("psm_relation_product_deadline", { readonly: false })
                            this.setField("psm_relation_product_revenues_date", { readonly: false })
                            this.setField("psm_relation_product_base_price", { readonly: false })
                            this.setField("psm_relation_product_margin", { readonly: false })
                            this.setField("psm_relation_product_value_uniti", { readonly: false })
                            this.setField("psm_relation_product_discount", { readonly: false })
                            this.setField("psm_relation_product_discount_percent", { readonly: false })
                            this.setField("psm_relation_product_show_budget", { readonly: false })
                            this.setField("psm_relation_product_icms_sell", { readonly: false })
                            this.setField("psm_relation_product_politic", { readonly: false })
                            this.setField("psm_relation_product_fk_fiscal_classification_id", { readonly: false })
                            this.setField("psm_relation_product_registered", { readonly: false })
                            this.setField("psm_relation_product_quantity", { readonly: false })
                            this.setField('psm_relation_product_fk_measure_unit_id', { readonly: false })
                        }
                    })
                        .catch((e: any) => {
                            console.log("catch e lab", e)
                            Notification.info({
                                title: 'Info',
                                description: e.response.data.message
                            })
                            this.setField('psm_relation_product_fk_select_id', { hidden: true })
                            this.setField('psm_relation_product_item_description', { hidden: false, disabled: true, readonly: true })
                            this.setField("psm_relation_product_registered", { readonly: true })
                            this.setField("psm_relation_product_quantity", { readonly: true, required: false })
                            this.setField('psm_relation_product_fk_measure_unit_id', { readonly: true })
                        }).finally(() => {
                            // this.setState("loadings", [])
                        })
                }
            }
        }


        if (this.globalProps.match.params.crud == "dibis") {
            const dadosPsm = this.globalProps.values;
            const matchParams = this.globalProps.match.params;
            if (this.globalProps.isEdit !== null) {
                // this.setState("loadings", ['psm_relation_product_fk_select_id', 'psm_relation_product_item_description', 'psm_relation_product_registered',
                //     'psm_relation_product_reproving', 'psm_relation_product_quantity', 'psm_relation_product_fk_measure_unit_id'
                // ]);
                await axios.get('/api/v1/check-item/product', {
                    params: {
                        crud: matchParams.crud,
                        idPsm: matchParams.id,
                        idProduct: dadosPsm.psm_relation_product_id,
                        tableName: this.globalProps.name
                    }
                }).then((response: any) => {
                    console.log("Valor do response dibi", response)
                    if (response.data.status == 200) {
                        Notification.info({
                            title: 'Permitido Alteração',
                            description: response.data.message
                        })
                        // if (situationIdDibi && situationIdDibi !== 3) {
                        this.setField("psm_relation_product_deadline", { readonly: false })
                        this.setField("psm_relation_product_revenues_date", { readonly: false })
                        this.setField("psm_relation_product_base_price", { readonly: false })
                        this.setField("psm_relation_product_margin", { readonly: false })
                        this.setField("psm_relation_product_value_uniti", { readonly: false })
                        this.setField("psm_relation_product_discount", { readonly: false })
                        this.setField("psm_relation_product_discount_percent", { readonly: false })
                        this.setField("psm_relation_product_show_budget", { readonly: false })
                        this.setField("psm_relation_product_icms_sell", { readonly: false })
                        this.setField("psm_relation_product_politic", { readonly: false })
                        this.setField("psm_relation_product_fk_fiscal_classification_id", { readonly: false })
                        this.setField("psm_relation_product_registered", { readonly: false })
                        this.setField("psm_relation_product_quantity", { readonly: false })
                        this.setField('psm_relation_product_fk_measure_unit_id', { readonly: false })
                        // }
                    }
                })
                    .catch((e: any) => {
                        console.log("catch e dibi", e)
                        Notification.info({
                            title: 'Info',
                            description: e.response.data.message
                        })
                        this.setField('psm_relation_product_fk_select_id', { hidden: true })
                        this.setField('psm_relation_product_item_description', { hidden: false, disabled: true, readonly: true })
                        this.setField("psm_relation_product_registered", { readonly: true })
                        this.setField("psm_relation_product_quantity", { readonly: true, required: false })
                        this.setField('psm_relation_product_fk_measure_unit_id', { readonly: true })
                    }).finally(() => {
                        // this.setState("loadings", [])
                    })
            }
        }


        if (['', undefined, null].includes(this.globalProps?.values?.psm_relation_product_origin_name)) {
            if (this.globalProps?.match?.url.includes('commercials') && this?.globalProps?.isEdit == null) {
                this.setFieldValue('psm_relation_product_origin_name', 'COMERCIAL')
            }
        }

        if (![null, undefined, ''].includes(this?.values?.psm_relation_product_requisition_number)) {
            this.setField("psm_relation_product_reproving", { readonly: true })
        } else {
            this.setField("psm_relation_product_reproving", { readonly: false })
        }

        this.setState("loadings", [])


        // if (situationIdDibi && situationIdDibi == 3) {
        //     for (const i in fieldColumns) {
        //         if (i !== '$GLOBALS') {
        //             if (!["psm_relation_product_base_price", "psm_relation_product_discount", "psm_relation_product_discount_percent"].includes(i)) {
        //                 // if (!["psm_relation_product_base_price", "psm_relation_product_discount", "psm_relation_product_discount_percent", "psm_relation_product_quantity", "psm_relation_product_fk_measure_unit_id"].includes(i)) {
        //                 this.setField(i, { readonly: true, disabled: true })
        //             }
        //         }
        //     }
        // }



    }
}



export class PsmRelationProductRegisteredEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        await this.setState("loadings", [
            "psm_relation_product_item_description",
            "psm_relation_product_intern_reference",
            "psm_relation_product_product_id",
            "psm_relation_product_fk_select_id",
            "psm_relation_product_fk_measure_unit_id",
            "psm_relation_product_base_price",
            "psm_relation_product_politic",
            "psm_relation_product_fk_fiscal_classification_id",
            "psm_relation_product_manufacture_code"
        ]);
        if (value == true) {
            this.setFieldValue("psm_relation_product_item_description", "");
            this.setFieldValue("psm_relation_product_intern_reference", "");
            this.setFieldValue("psm_relation_product_fk_measure_unit_id", -1);
            // this.setFieldValue("psm_relation_product_base_price", 0);
            // this.setFieldValue("psm_relation_product_politic", 0);
            // this.setFieldValue("psm_relation_product_margin", 0);
            this.setFieldValue("psm_relation_product_manufacture_code", "")
            // this.setFieldValue("psm_relation_product_value_uniti", 0);
            this.setFieldValue("psm_relation_product_fk_fiscal_classification_id", -1);
            this.setFieldValue("psm_relation_product_product_id", "");
            this.setFieldValue("psm_relation_product_fk_select_id", -1);
        };
        setTimeout(() => {
            this.setState("loadings", [])
        }, 3000)
    }
}