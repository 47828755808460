import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmCarriersFkPersonIdEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange = async (value: any, setField: any, setState: any, values: string) => {

     
        this.setState("loadings", [
            "psm_cnpj_transport", "psm_city_transport"
        ]);

        if(value){
            axios
            .get(`/api/v1/psm/getcarrierinfos/${value}`)
            .then((res) => {
                const infos = res.data.infos
                this.setFieldValue("psm_cnpj_transport", infos.cnpj)
                this.setFieldValue("psm_city_transport", infos.city)
            })
        }

        if(value == null){
            this.setFieldValue("psm_cnpj_transport", '')
            this.setFieldValue("psm_city_transport", '')
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 2000)



    }

}