import { Event } from "../Event";
import axios from "../../utilities/axios";

export class ProductBoxShippmentFkPersonIdEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange = async (value: any, setField: any, setState: any, values: string) => {

        this.setState("loadings", [
            "product_box_cnpj_transport", "product_box_city_transport"
        ]);

        if(value){
            axios
                .get(`/api/v1/psm/getcarrierinfos/${value}`)
                .then((res) => {
                    const infos = res.data.infos
                    this.setFieldValue("product_box_cnpj_transport", infos.cnpj)
                    this.setFieldValue("product_box_city_transport", infos.city)
                })
        }

        if(value == null){
            this.setFieldValue("product_box_cnpj_transport", '')
            this.setFieldValue("product_box_city_transport", '')
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 2000)

    }

}