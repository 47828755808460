import React from "react";
import { UserContext } from "../components/authProvider/main";
import { Event } from "../events/Event";

export class NotSeeEvents extends Event {
    static notSee(table: string) {
        let fieldNotSee: any;
        switch (table) {
            case 'commercials':

                fieldNotSee = [
                    { name: 'psm_display_value_new', properties: { hidden: true } },
                    { name: 'psm_new_equipment_value', properties: { hidden: true } },
                    { name: 'psm_refer_equipment_new', properties: { hidden: true } },
                    { name: 'psm_analisys_hour_budget', properties: { hidden: true } },
                    { name: 'psm_conclusion_hours_finish', properties: { hidden: true } },
                    { name: 'psm_minimum_value', properties: { hidden: true } },
                    { name: 'psm_hour_value', properties: { hidden: true } },
                    { name: 'psm_considered_value', properties: { hidden: true } },
                    { name: 'psm_considered_discount_value', properties: { hidden: true } },
                    { name: 'psm_total_value_products', properties: { hidden: true } },
                    { name: 'psm_value_product_discount', properties: { hidden: true } },
                    { name: 'psm_value_service_recommended', properties: { hidden: true } },
                    { name: 'psm_value_product_discount', properties: { hidden: true } },
                    { name: 'psm_value_service_recommended_discount', properties: { hidden: true } },
                    { name: 'psm_total_product_service', properties: { hidden: true } },
                    { name: 'psm_total_product_service_discount', properties: { hidden: true } },
                    { name: 'psm_final_value', properties: { width: 2 } }

                ];
                return fieldNotSee;

            case 'psm_relation_products':
                fieldNotSee = [
                    { name: 'psm_relation_product_customer_order', properties: { hidden: true } },
                    { name: 'psm_relation_product_iten_client', properties: { hidden: true } },
                    { name: 'psm_relation_product_product_id', properties: { hidden: true } },
                    { name: 'psm_relation_product_sales_history', properties: { hidden: true } },
                    { name: 'psm_relation_product_show_budget', properties: { hidden: true } },
                    { name: 'psm_relation_product_quant_avaliabler', properties: { hidden: true } },
                    { name: 'psm_relation_product_obs', properties: { hidden: true } },
                    { name: 'psm_relation_product_fk_select_id', properties: { hidden: true } },
                    { name: 'psm_relation_product_supplementary_description', properties: { hidden: true } },
                    { name: 'psm_relation_product_div', properties: { hidden: true } },
                    { name: 'psm_relation_product_ncm', properties: { hidden: true } },
                    { name: 'psm_relation_product_fk_fiscal_classification_id', properties: { hidden: true } },
                    { name: 'psm_relation_product_ipi', properties: { hidden: true } },
                    { name: 'psm_relation_product_icms_anticipation', properties: { hidden: true } },
                    { name: 'psm_relation_product_icms', properties: { hidden: true } },
                    { name: 'psm_relation_product_icms_interstate', properties: { hidden: true } },
                    { name: 'psm_relation_product_icms_sell', properties: { hidden: true } },
                    { name: 'psm_relation_product_st', properties: { hidden: true } },
                    { name: 'psm_relation_product_div2', properties: { hidden: true } },
                    { name: 'psm_relation_product_manufacture_code', properties: { hidden: true } },
                    { name: 'psm_relation_product__id', properties: { hidden: true } },
                    { name: 'psm_relation_product_bound', properties: { hidden: true } },
                    { name: 'psm_relation_product_analysi_id', properties: { hidden: true } },
                    { name: 'psm_relation_product_requisition_number', properties: { hidden: true } },
                    { name: 'psm_relation_product_observation_commercial', properties: { hidden: true } },
                    { name: 'psm_relation_product_revenues_date', properties: { hidden: true } },
                    { name: 'psm_relation_product_recomended', properties: { hidden: true } },
                    { name: 'psm_relation_product_base_price', properties: { hidden: true } },
                    { name: 'psm_relation_product_politic', properties: { hidden: true } },
                    { name: 'psm_relation_product_margin', properties: { hidden: true } },
                    // { name: 'psm_relation_product_value_uniti', properties: { hidden: true } },
                    { name: 'psm_relation_product_value_discount_uniti', properties: { hidden: true } },
                    { name: 'psm_relation_product_discount', properties: { hidden: true } },
                    { name: 'psm_relation_product_discount_percent', properties: { hidden: true } },
                    { name: 'psm_relation_product_discount_value_all', properties: { hidden: true } },
                    { name: 'psm_relation_product_deadline', properties: { hidden: true } },
                    { name: 'psm_relation_product_fk_measure_unit_id', properties: { hidden: true } }


                ];
                return fieldNotSee;


            case 'psm_relation_services':
                fieldNotSee = [
                    { name: 'psm_relation_service_customer_order', properties: { hidden: true } },
                    { name: 'psm_relation_service_iten_client', properties: { hidden: true } },
                    { name: 'psm_relation_service_product_id', properties: { hidden: true } },
                    { name: 'psm_relation_service_sales_history', properties: { hidden: true } },
                    { name: 'psm_relation_service_show_budget', properties: { hidden: true } },
                    { name: 'psm_relation_service_quant_avaliabler', properties: { hidden: true } },
                    { name: 'psm_relation_service_obs', properties: { hidden: true } },
                    { name: 'psm_relation_service_fk_select_id', properties: { hidden: true } },
                    { name: 'psm_relation_service_supplementary_description', properties: { hidden: true } },
                    { name: 'psm_relation_service_div', properties: { hidden: true } },
                    { name: 'psm_relation_service_ncm', properties: { hidden: true } },
                    { name: 'psm_relation_service_fk_fiscal_classification_id', properties: { hidden: true } },
                    { name: 'psm_relation_service_ipi', properties: { hidden: true } },
                    { name: 'psm_relation_service_icms_anticipation', properties: { hidden: true } },
                    { name: 'psm_relation_service_icms', properties: { hidden: true } },
                    { name: 'psm_relation_service_icms_interstate', properties: { hidden: true } },
                    { name: 'psm_relation_service_icms_sell', properties: { hidden: true } },
                    { name: 'psm_relation_service_st', properties: { hidden: true } },
                    { name: 'psm_relation_service_div2', properties: { hidden: true } },
                    { name: 'psm_relation_service_manufacture_code', properties: { hidden: true } },
                    { name: 'psm_relation_service__id', properties: { hidden: true } },
                    { name: 'psm_relation_service_bound', properties: { hidden: true } },
                    { name: 'psm_relation_service_analysi_id', properties: { hidden: true } },
                    { name: 'psm_relation_service_requisition_number', properties: { hidden: true } },
                    { name: 'psm_relation_service_observation_commercial', properties: { hidden: true } },
                    { name: 'psm_relation_service_revenues_date', properties: { hidden: true } },
                    { name: 'psm_relation_service_recomended', properties: { hidden: true } },
                    { name: 'psm_relation_service_base_price', properties: { hidden: true } },
                    { name: 'psm_relation_service_politic', properties: { hidden: true } },
                    { name: 'psm_relation_service_margin', properties: { hidden: true } },
                    // { name: 'psm_relation_service_value_uniti', properties: { hidden: true } },
                    { name: 'psm_relation_service_value_discount_uniti', properties: { hidden: true } },
                    { name: 'psm_relation_service_discount', properties: { hidden: true } },
                    { name: 'psm_relation_service_discount_percent', properties: { hidden: true } },
                    { name: 'psm_relation_service_discount_value_all', properties: { hidden: true } },
                    { name: 'psm_relation_service_deadline', properties: { hidden: true } },
                    { name: 'psm_relation_service_fk_measure_unit_id', properties: { hidden: true } },
                    { name: 'psm_relation_service_observation_commercial', properties: { hidden: true } }

                ];
                return fieldNotSee;


            case 'psm_recommended_services':
                fieldNotSee = [
                    { name: 'psm_recommended_service_discount', properties: { hidden: true } },
                    { name: 'psm_recommended_service_discount_percent', properties: { hidden: true } },
                    { name: 'psm_recommended_service_discount_value_all', properties: { hidden: true } },
                    { name: 'psm_recommended_service_chek_include', properties: { hidden: true } },
                    { name: 'psm_recommended_service_show_budget', properties: { hidden: true } },
                    { name: 'psm_recommended_service_origin_name', properties: { width: 4 } },
                    { name: 'psm_recommended_service_fk_service_solution_id', properties: { width: 8 } },
                    { name: 'psm_recommended_service_check_executed', properties: { width: 3 } },
                    { name: 'psm_recommended_service_reproving', properties: { width: 3 } },
                    { name: 'psm_recommended_service_value_all', properties: { width: 6 } },
                ];
                return fieldNotSee;


            default:
                console.log("Tabela não reconhecida");
                return null;
        }
    }
}
