
import axios from "../../../utilities/axios";
import { NotSeeEvents } from "../../../utils/isNotSee";
import { Event } from "../../Event";
import { Notification } from "rsuite";


const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
    };
};

export class PsmRecommendedServiceDiscountPercentEvents extends Event {
    public onBlur = (value: any) => {
        console.log('Executer')
        var dadosPsm = this.globalState.formValues;
        var porcentagem = parseFloat(value);
        var inicial = dadosPsm.psm_recommended_service_value_all;
        var resultado = calculaValorTotal(inicial, porcentagem, 0);
        if (resultado.valorTotal > 0) {
            this.setFieldValue("psm_recommended_service_discount_value_all", resultado.valorTotal);
            this.setFieldValue("psm_recommended_service_discount", resultado.desconto);
            // this.setFieldValue("psm_discount_percentage", value.toFixed(2));
        } else {
            Notification.error({
                title: "Percentual de desconto inválido",
            });
            this.setFieldValue("psm_recommended_service_discount_percent", 0);
            this.setFieldValue("psm_recommended_service_discount", 0);
            this.setFieldValue("psm_recommended_service_discount_value_all", this.globalState.formValues.psm_relation_service_value_all);
        }
    };
}

export class PsmRecommendedServiceEvents extends Event {
    public onLoad = async (value: any, setField: any, setState: any) => {
        // console.log('teste ::::> ', this.values)         

        let infosRoles: any

        console.log("this.props :>", this)

        if(this.globalProps.isEdit == null){
            if(this.globalProps.location.pathname.includes("commercials")){
                this.setFieldValue("psm_recommended_service_origin_name", "COMERCIAL")
            }
        }

        await axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                infosRoles = subData.user.role.role_permissions

                // if (subData) {
                //     this.setFieldValue(ƒ"psm_user", subData.user.user_name);
                // }
            })

        for (const t in infosRoles) {
            if (infosRoles[t].role_permission_value == 'commercials_viewfields') {
                const notSee: any = NotSeeEvents.notSee('psm_recommended_services')

                for (const field of notSee) {
                    this.setField(field.name, field.properties);
                }
                this.setField("psm_recommended_service_fk_service_solution_id", {
                    readonly: true,
                    disabled: true
                })
                this.setField("psm_recommended_service_value_all", {
                    readonly: true,
                    disabled: true
                })
            }
        }
    }
}

export class PsmRecommendedServiceDiscountEvents extends Event {
    public onBlur = (value: any) => {
        var dadosPsm = this.globalState.formValues;
        var desconto = parseFloat(value);
        var inicial = dadosPsm.psm_recommended_service_value_all;

        var resultado = calculaValorTotal(inicial, 0, desconto);
        if (resultado.valorTotal > 0) {
            this.setFieldValue("psm_recommended_service_discount_value_all", resultado.valorTotal);
            this.setFieldValue("psm_recommended_service_discount_percent", resultado.percentual);
            // this.setFieldValue("psm_discount", value.toFixed(2));
        } else {
            Notification.error({
                title: "Valor do desconto inválido",
            });
            this.setFieldValue("psm_recommended_service_discount_percent", 0);
            this.setFieldValue("psm_recommended_service_discount", 0);
            this.setFieldValue("psm_recommended_service_discount_value_all", this.globalState.formValues.psm_relation_service_value_all);
        }
    };
}