import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { NULL } from "sass";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { NotSeeEvents } from "../../utils/isNotSee"

dayjs.extend(weekday);

// function mostrarSomenteColunas(indices: number[]): void {
//     const gridContainer = document.getElementById("psm_relation_products");

//     if (!gridContainer) {
//         console.error("Grid container not found.");
//         return;
//     }

//     const allHeaders = gridContainer.querySelectorAll<HTMLElement>('.rs-table-cell-header');
//     const allCells = gridContainer.querySelectorAll<HTMLElement>('.rs-table-cell');

//     allHeaders.forEach(header => {
//         header.style.width = '0';
//         header.style.minWidth = '0';
//         header.style.maxWidth = '0';
//         header.style.overflow = 'hidden';
//     });
//     allCells.forEach(cell => {
//         cell.style.width = '0';
//         cell.style.minWidth = '0';
//         cell.style.maxWidth = '0';
//         cell.style.overflow = 'hidden';
//     });

//     indices.forEach(index => {
//         const header = gridContainer.querySelector<HTMLElement>(`.rs-table-cell-header[aria-colindex="${index}"]`);
//         if (header) {
//             header.style.width = '';
//             header.style.minWidth = '';
//             header.style.maxWidth = '';
//             header.style.overflow = '';
//         } else {
//             console.warn(`Header for column index ${index} not found.`);
//         }

//         const cells = gridContainer.querySelectorAll<HTMLElement>(`.rs-table-cell[aria-colindex="${index}"]`);
//         cells.forEach(cell => {
//             cell.style.width = '';
//             cell.style.minWidth = '';
//             cell.style.maxWidth = '';
//             cell.style.overflow = '';
//         });
//         if (cells.length === 0) {
//             console.warn(`Cells for column index ${index} not found.`);
//         }
//     });
//     gridContainer.style.display = 'none'; // Força a tabela a recalcular seu layout
//     setTimeout(() => {
//         gridContainer.style.display = ''; // Mostrar a tabela novamente
//     }, 0);
// }

const allowedColumns = [
    'referência interna (ri)',
    'descrição do item',
    'quantidade',
    'origem',
    'valor original',
    'não cadastrado',
    "instalado",
    "reprovado"
];

function updateTable() {
    const gridElements = document.querySelectorAll('#psm_relation_products, #psm_relation_ ervices');

    gridElements.forEach((gridElement) => {
        const grid = gridElement as HTMLElement;
        const columnsToKeep = new Set<string>();

        const headerCells = grid.querySelectorAll<HTMLElement>(
            'div.rs-table-cell-header.filterable div[role="columnheader"], div.rs-table-cell-group-fixed-right div[role="columnheader"], div.rs-table-cell-group-fixed-left div[role="columnheader"]'
        );

        let actionColumnIndex = '';

        headerCells.forEach((headerCell) => {
            const colIndex = headerCell.getAttribute('aria-colindex') || '';
            const headerTextElement = headerCell.querySelector<HTMLElement>(
                '.rs-table-cell-content .filterable-header > div:first-child, .rs-table-cell-content'
            );
            const headerText = headerTextElement?.textContent?.trim().toLowerCase() || '';

            if (headerText === 'ações' && colIndex) {
                columnsToKeep.add(colIndex);
                actionColumnIndex = colIndex;
            } else if (allowedColumns.includes(headerText) && colIndex) {
                columnsToKeep.add(colIndex);
            }
        });

        headerCells.forEach((headerCell) => {
            const colIndex = headerCell.getAttribute('aria-colindex');

            if (colIndex && !columnsToKeep.has(colIndex)) {
                const parentCellHeader = headerCell.closest<HTMLElement>(
                    'div.rs-table-cell-header.filterable, div.rs-table-cell-group-fixed-right, div.rs-table-cell-group-fixed-left'
                );
                if (parentCellHeader && parentCellHeader.parentNode) {
                    parentCellHeader.parentNode.removeChild(parentCellHeader);
                }

                const resizer = headerCell.nextElementSibling as HTMLElement | null;
                if (
                    resizer &&
                    resizer.classList.contains('rs-table-column-resize-spanner') &&
                    resizer.parentNode
                ) {
                    resizer.parentNode.removeChild(resizer);
                }
            }
        });

        const dataRows = grid.querySelectorAll<HTMLElement>(
            'div.rs-table-body-row-wrapper div[role="row"]'
        );

        dataRows.forEach((row) => {
            const dataCells = row.querySelectorAll<HTMLElement>(
                'div[role="gridcell"], div.rs-table-cell-group-fixed-right div[role="gridcell"], div.rs-table-cell-group-fixed-left div[role="gridcell"]'
            );

            dataCells.forEach((dataCell) => {
                const colIndex = dataCell.getAttribute('aria-colindex');

                if (colIndex && !columnsToKeep.has(colIndex) && dataCell.parentNode) {
                    dataCell.parentNode.removeChild(dataCell);
                }

                if (colIndex === actionColumnIndex) {
                    dataCell.innerHTML = `
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <i class="fas fa-eye text-primary fa-fw mr-2 clickable" title="Visualizar"></i>
                            <i class="fas fa-pencil-alt text-warning fa-fw mr-2 clickable" title="Editar"></i>
                            <i class="fas fa-trash text-danger fa-fw mr-2 clickable" title="Excluir"></i>
                        </div>
                    `;
                }
            });
        });

        adjustCellPositions(grid);
    });
}

function adjustCellPositions(grid: HTMLElement) {
    let currentLeft = 0;

    const visibleHeaderCells = grid.querySelectorAll<HTMLElement>(
        'div.rs-table-cell-header.filterable div[role="columnheader"]'
    );

    visibleHeaderCells.forEach((headerCell) => {
        const cellElement = headerCell;
        const widthStr = cellElement.style.width;
        const width = widthStr ? parseFloat(widthStr) : 0;

        cellElement.style.left = `${currentLeft}px`;

        const cellHeaderGroup = headerCell.closest<HTMLElement>(
            '.rs-table-cell-header.filterable'
        );
        if (cellHeaderGroup) {
            cellHeaderGroup.style.left = `${currentLeft}px`;
        }

        currentLeft += width;
    });

    const dataRows = grid.querySelectorAll<HTMLElement>(
        'div.rs-table-body-row-wrapper div[role="row"]'
    );

    dataRows.forEach((row) => {
        let rowLeft = 0;
        const dataCells = row.querySelectorAll<HTMLElement>('div[role="gridcell"]');

        dataCells.forEach((dataCell) => {
            const cellElement = dataCell;
            const widthStr = cellElement.style.width;
            const width = widthStr ? parseFloat(widthStr) : 0;

            cellElement.style.left = `${rowLeft}px`;

            rowLeft += width;
        });
    });

    const normalColumnsWidth = currentLeft;

    let fixedRightWidth = 0;
    const fixedRightCells = grid.querySelectorAll<HTMLElement>(
        'div.rs-table-cell-group-fixed-right div[role="columnheader"]'
    );
    fixedRightCells.forEach((cell) => {
        const cellElement = cell.closest<HTMLElement>('.rs-table-cell-group-fixed-right');
        const widthStr = cellElement?.style.width || cell.style.width;
        const width = widthStr ? parseFloat(widthStr) : 0;
        fixedRightWidth += width;
    });

    const cellGroups = grid.querySelectorAll<HTMLElement>('.rs-table-cell-group-scroll');
    cellGroups.forEach((group) => {
        group.style.width = `${normalColumnsWidth}px`;
    });

    const tableRows = grid.querySelectorAll<HTMLElement>('.rs-table-row');
    tableRows.forEach((tableRow) => {
        tableRow.style.minWidth = `${normalColumnsWidth + fixedRightWidth}px`;
    });

    const fixedGroups = grid.querySelectorAll<HTMLElement>(
        '.rs-table-cell-group-fixed-right, .rs-table-cell-group-fixed-left'
    );
    fixedGroups.forEach((group) => {
        group.style.zIndex = '1';
    });

    adjustScroll(grid, normalColumnsWidth, fixedRightWidth);
}

function adjustScroll(grid: HTMLElement, normalColumnsWidth: number, fixedRightWidth: number) {
    const tableBody = grid.querySelector<HTMLElement>('div.rs-table-body');
    const tableBodyWrapper = grid.querySelector<HTMLElement>(
        'div.rs-table-body-row-wrapper'
    );
    if (tableBody && tableBodyWrapper) {
        const totalWidth = normalColumnsWidth + fixedRightWidth;
        tableBodyWrapper.style.width = `${normalColumnsWidth}px`;
        tableBody.style.width = `${totalWidth}px`;
    }

    const horizontalScrollbar = grid.querySelector<HTMLElement>(
        '.rs-table-scrollbar-horizontal'
    );
    if (horizontalScrollbar && tableBodyWrapper) {
        const scrollbarHandle = horizontalScrollbar.querySelector<HTMLElement>(
            '.rs-table-scrollbar-handle'
        );
        if (scrollbarHandle) {
            const containerWidth = normalColumnsWidth;
            const visibleWidth = grid.clientWidth - fixedRightWidth;
            const handleWidth = (visibleWidth / containerWidth) * 100;
            scrollbarHandle.style.width = `${handleWidth}%`;
        }
    }

    const verticalScrollbar = grid.querySelector<HTMLElement>(
        '.rs-table-scrollbar-vertical'
    );
    if (verticalScrollbar && tableBodyWrapper) {
        const scrollbarHandle = verticalScrollbar.querySelector<HTMLElement>(
            '.rs-table-scrollbar-handle'
        );
        if (scrollbarHandle) {
            const tableBodyHeight = tableBodyWrapper.clientHeight;
            const tableContentHeight = tableBodyWrapper.scrollHeight;
            const handleHeight = (tableBodyHeight / tableContentHeight) * 100;
            scrollbarHandle.style.height = `${handleHeight}%`;
        }
    }
}




/* Testando questão do view vvvvvvvv */

// const allowedColumns = [
//     'referência interna (ri)',
//     'descrição do item',
//     'quantidade',
//     'origem',
//     'valor original',
//     'não cadastrado',
//     "instalado",
//     "reprovado"
// ];


// function updateTable() {
//     const gridElements = document.querySelectorAll('#psm_relation_products, #psm_relation_services');

//     gridElements.forEach((gridElement) => {
//         const grid = gridElement as HTMLElement;
//         const columnsToKeep = new Set<string>();

//         const headerCells = grid.querySelectorAll<HTMLElement>(
//             'div.rs-table-cell-header.filterable div[role="columnheader"], div.rs-table-cell-group-fixed-right div[role="columnheader"], div.rs-table-cell-group-fixed-left div[role="columnheader"]'
//         );

//         let actionColumnIndex = '';

//         // Primeira varredura para identificar quais colunas devem ser mantidas
//         headerCells.forEach((headerCell) => {
//             const colIndex = headerCell.getAttribute('aria-colindex') || '';
//             const headerTextElement = headerCell.querySelector<HTMLElement>(
//                 '.rs-table-cell-content .filterable-header > div:first-child, .rs-table-cell-content'
//             );
//             const headerText = headerTextElement?.textContent?.trim().toLowerCase() || '';

//             if (headerText === 'ações' && colIndex) {
//                 // A coluna "ações" deve ser sempre mantida
//                 columnsToKeep.add(colIndex);
//                 actionColumnIndex = colIndex;
//             } else if (allowedColumns.includes(headerText) && colIndex) {
//                 columnsToKeep.add(colIndex);
//             }
//         });

//         // Atualizar apenas as colunas que não pertencem à seção fixa à direita
//         headerCells.forEach((headerCell) => {
//             const colIndex = headerCell.getAttribute('aria-colindex');

//             // Não remover ou alterar a coluna "ações"
//             if (colIndex && colIndex !== actionColumnIndex && !columnsToKeep.has(colIndex)) {
//                 const parentCellHeader = headerCell.closest<HTMLElement>(
//                     'div.rs-table-cell-header.filterable, div.rs-table-cell-group-fixed-left'
//                 );
//                 if (parentCellHeader && parentCellHeader.parentNode) {
//                     parentCellHeader.parentNode.removeChild(parentCellHeader);
//                 }

//                 const resizer = headerCell.nextElementSibling as HTMLElement | null;
//                 if (
//                     resizer &&
//                     resizer.classList.contains('rs-table-column-resize-spanner') &&
//                     resizer.parentNode
//                 ) {
//                     resizer.parentNode.removeChild(resizer);
//                 }
//             }
//         });

//         const dataRows = grid.querySelectorAll<HTMLElement>(
//             'div.rs-table-body-row-wrapper div[role="row"]'
//         );

//         // Atualizar as células dos dados, mantendo intacta a coluna "ações"
//         dataRows.forEach((row) => {
//             const dataCells = row.querySelectorAll<HTMLElement>(
//                 'div[role="gridcell"], div.rs-table-cell-group-fixed-left div[role="gridcell"], div.rs-table-cell-group-fixed-right div[role="gridcell"]'
//             );

//             dataCells.forEach((dataCell) => {
//                 const colIndex = dataCell.getAttribute('aria-colindex');

//                 // Não remover ou alterar a célula da coluna "ações"
//                 if (colIndex && colIndex !== actionColumnIndex && !columnsToKeep.has(colIndex) && dataCell.parentNode) {
//                     dataCell.parentNode.removeChild(dataCell);
//                 }

//                 // Garantir que a coluna "ações" contenha sempre os botões
//                 if (colIndex === actionColumnIndex) {
//                     dataCell.innerHTML = `
//                         <div style="display: flex; justify-content: center; align-items: center;">
//                             <i class="fas fa-eye text-primary fa-fw mr-2 clickable" title="Visualizar"></i>
//                             <i class="fas fa-pencil-alt text-warning fa-fw mr-2 clickable" title="Editar"></i>
//                             <i class="fas fa-trash text-danger fa-fw mr-2 clickable" title="Excluir"></i>
//                         </div>
//                     `;
//                 }
//             });
//         });

//         adjustCellPositions(grid);
//     });
// }

// function adjustCellPositions(grid: HTMLElement) {
//     let currentLeft = 0;

//     const visibleHeaderCells = grid.querySelectorAll<HTMLElement>(
//         'div.rs-table-cell-header.filterable div[role="columnheader"]'
//     );

//     visibleHeaderCells.forEach((headerCell) => {
//         const cellElement = headerCell;
//         const widthStr = cellElement.style.width;
//         const width = widthStr ? parseFloat(widthStr) : 0;

//         cellElement.style.left = `${currentLeft}px`;

//         const cellHeaderGroup = headerCell.closest<HTMLElement>(
//             '.rs-table-cell-header.filterable'
//         );
//         if (cellHeaderGroup) {
//             cellHeaderGroup.style.left = `${currentLeft}px`;
//         }

//         currentLeft += width;
//     });

//     const dataRows = grid.querySelectorAll<HTMLElement>(
//         'div.rs-table-body-row-wrapper div[role="row"]'
//     );

//     dataRows.forEach((row) => {
//         let rowLeft = 0;
//         const dataCells = row.querySelectorAll<HTMLElement>('div[role="gridcell"]');

//         dataCells.forEach((dataCell) => {
//             const cellElement = dataCell;
//             const widthStr = cellElement.style.width;
//             const width = widthStr ? parseFloat(widthStr) : 0;

//             cellElement.style.left = `${rowLeft}px`;

//             rowLeft += width;
//         });
//     });

//     const normalColumnsWidth = currentLeft;

//     let fixedRightWidth = 0;
//     const fixedRightCells = grid.querySelectorAll<HTMLElement>(
//         'div.rs-table-cell-group-fixed-right div[role="columnheader"]'
//     );
//     fixedRightCells.forEach((cell) => {
//         const cellElement = cell.closest<HTMLElement>('.rs-table-cell-group-fixed-right');
//         const widthStr = cellElement?.style.width || cell.style.width;
//         const width = widthStr ? parseFloat(widthStr) : 0;
//         fixedRightWidth += width;
//     });

//     const cellGroups = grid.querySelectorAll<HTMLElement>('.rs-table-cell-group-scroll');
//     cellGroups.forEach((group) => {
//         group.style.width = `${normalColumnsWidth}px`;
//     });

//     const tableRows = grid.querySelectorAll<HTMLElement>('.rs-table-row');
//     tableRows.forEach((tableRow) => {
//         tableRow.style.minWidth = `${normalColumnsWidth + fixedRightWidth}px`;
//     });

//     const fixedGroups = grid.querySelectorAll<HTMLElement>(
//         '.rs-table-cell-group-fixed-right, .rs-table-cell-group-fixed-left'
//     );
//     fixedGroups.forEach((group) => {
//         group.style.zIndex = '1';
//     });

//     adjustScroll(grid, normalColumnsWidth, fixedRightWidth);
// }

// function adjustScroll(grid: HTMLElement, normalColumnsWidth: number, fixedRightWidth: number) {
//     const tableBody = grid.querySelector<HTMLElement>('div.rs-table-body');
//     const tableBodyWrapper = grid.querySelector<HTMLElement>(
//         'div.rs-table-body-row-wrapper'
//     );
//     if (tableBody && tableBodyWrapper) {
//         const totalWidth = normalColumnsWidth + fixedRightWidth;
//         tableBodyWrapper.style.width = `${normalColumnsWidth}px`;
//         tableBody.style.width = `${totalWidth}px`;
//     }

//     const horizontalScrollbar = grid.querySelector<HTMLElement>(
//         '.rs-table-scrollbar-horizontal'
//     );
//     if (horizontalScrollbar && tableBodyWrapper) {
//         const scrollbarHandle = horizontalScrollbar.querySelector<HTMLElement>(
//             '.rs-table-scrollbar-handle'
//         );
//         if (scrollbarHandle) {
//             const containerWidth = normalColumnsWidth;
//             const visibleWidth = grid.clientWidth - fixedRightWidth;
//             const handleWidth = (visibleWidth / containerWidth) * 100;
//             scrollbarHandle.style.width = `${handleWidth}%`;
//         }
//     }

//     const verticalScrollbar = grid.querySelector<HTMLElement>(
//         '.rs-table-scrollbar-vertical'
//     );
//     if (verticalScrollbar && tableBodyWrapper) {
//         const scrollbarHandle = verticalScrollbar.querySelector<HTMLElement>(
//             '.rs-table-scrollbar-handle'
//         );
//         if (scrollbarHandle) {
//             const tableBodyHeight = tableBodyWrapper.clientHeight;
//             const tableContentHeight = tableBodyWrapper.scrollHeight;
//             const handleHeight = (tableBodyHeight / tableContentHeight) * 100;
//             scrollbarHandle.style.height = `${handleHeight}%`;
//         }
//     }
// }



function verificarURLYAtivarMostrarSomenteColunas(): void {
    const urlAtual = window.location.href;
    var isTrue: any = ''
    if (urlAtual.includes('?tab=budget') && isTrue == false) {
        isTrue = true
        updateTable();
    } else {
        isTrue = false
    }
    // console.log("isTrue :>", isTrue)
}

export class LabEvents extends Event {


    // static async converterData(dataAmericana: string) {
    //     if (dataAmericana && dataAmericana.length > 8) {

    //         const partes = dataAmericana?.split('-');
    //         const splitData = partes[2]?.split('T');

    //         const mes = partes[1];
    //         const dia = splitData[0];
    //         const ano = partes[0];
    //         const dataBrasileira = `${dia}/${mes}/${ano}`;
    //         return dataBrasileira;
    //     } 
    // }
    public onLoad = async () => {
        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm')
        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutTwoTableEvents extends Event {
    public onLoad = async () => {

        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');
        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutThreeTableEvents extends Event {
    public onLoad = async () => {

        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }
        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutFourTableEvents extends Event {
    public onLoad = async () => {

        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }


        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {

                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutFiveTableEvents extends Event {
    public onLoad = async () => {

        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutSixTableEvents extends Event {
    public onLoad = async () => {

        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');


        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutSevenTableEvents extends Event {
    public onLoad = async () => {

        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');


        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutEightTableEvents extends Event {
    public onLoad = async () => {

        // const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutNineTableEvents extends Event {
    public onLoad = async () => {

        // const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutTenTableEvents extends Event {
    public onLoad = async () => {

        // const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm');

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {
            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })
        }

        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutElevenTableEvents extends Event {
    public onLoad = async () => {

        const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)


        var dadosPsm = this.globalProps.values;

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm')

        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {

            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readonly: true,
                disabled: true
            })

            this.setField("psm_combined_analysis_obs_client", {
                readonly: true,
                disabled: true
            })

        }

        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }

        if (dadosPsm.psm_equipment_type_fk_type_equipment_id !== 9) {
            this.setField("psm_tcs_conditions", {
                disabled: true
            })
            this.setField("psm_defects_services_tcs_custom", {
                hidden: true
            })
            this.setField("psm_tc_possible_causes", {
                gridAdd: false
            })
            this.setField("psm_inclusion_product_tcs", {
                gridAdd: false
            })
            this.setField("psm_tcs_attachments", {
                hidden: true
            })
            this.setField("psm_tcs_obs", {
                hidden: true
            })
        }

        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }
    }
}
export class LayoutTwelveTableEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {

    };

    public onLoad = async () => {

        // const values = this.values;

        // this.setState("loadings", ['psm_date', 'psm_equipment_checked', 'psm_deadline_analysis'])
        // if (values?.psm_date && values?.psm_date !== undefined) {
        //     const dataCorretaApprove = await LabEvents.converterData(values.psm_date);
        //     // console.log("dataCorretaDelivery :>", dataCorretaApprove)

        //     this.setFieldValue("psm_date", '')
        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_date", dataCorretaApprove)
        //     }
        // }

        // if (values?.psm_equipment_checked && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDelivery = await LabEvents.converterData(values.psm_equipment_checked);
        //     // console.log("dataCorretaDelivery :>", dataCorretaDelivery)
        //     this.setFieldValue("psm_equipment_checked", '')
        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_equipment_checked", dataCorretaDelivery)
        //     }
        // }

        // if (values?.psm_deadline_analysis && values?.psm_equipment_checked !== undefined) {
        //     const dataCorretaDeliveryChange = await LabEvents.converterData(values.psm_deadline_analysis);

        //     this.setFieldValue("psm_deadline_analysis", '')
        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_deadline_analysis", dataCorretaDeliveryChange)
        //     }
        // }

        // setTimeout(() => {
        //     this.setState("loadings", [])
        // }, 2000)

        var dadosPsm = this.globalProps.values;
        if (this.globalProps.values.psm_status_fk_situation_id >= 6) {
            this.setField('psm_custom_add', {
                hidden: true
            })
        }

        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm')


        if (this.globalProps.values.psm_status_fk_situation_id !== 5) {

            this.setField("psm_optional_analystics", {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_compiled_defects', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compiled_possible_causes', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField('psm_analyze_compileds', {
                gridEdit: false,
                gridAdd: false,
                gridDelete: false,
                gridView: true
            })

            this.setField("psm_combined_analysis_obs", {
                readOnly: true
            })


            this.setField("psm_combined_analysis_obs_client", {
                readOnly: true
            })
        }


        // console.log("Ok aqui", dadosPsm.psm_status_sensor);
        if (dadosPsm.psm_status_sensor == null) {
            this.setFieldValue("psm_status_sensor", { value: 1, label: "PRESENTE" });
        }

        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }

    }
}

export class CommercialEvents extends Event {




    public onLoad = async (value: any) => {


        let infosRoles: any


        await axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                infosRoles = subData.user.role.role_permissions

                // if (subData) {
                //     this.setFieldValue(ƒ"psm_user", subData.user.user_name);
                // }
            })


        if (this.globalProps.values?.psm_carriers_fk_person_id) {
            this.setState("loadings", [
                "psm_cnpj_transport", "psm_city_transport"
            ]);

            axios
                .get(`/api/v1/psm/getcarrierinfos/${this.globalProps.values?.psm_carriers_fk_person_id}`)
                .then((res) => {
                    const infos = res.data.infos
                    this.setFieldValue("psm_cnpj_transport", infos.cnpj)
                    this.setFieldValue("psm_city_transport", infos.city)
                })
        }


        for (const t in infosRoles) {
            if (infosRoles[t].role_permission_value == 'commercials_viewfields') {
                const notSee: any = NotSeeEvents.notSee('commercials')

                for (const field of notSee) {
                    this.setField(field.name, field.properties);
                }

                this.setState("loadings", ["psm_relation_products", "psm_relation_services"]);

                this.setField("psm_relation_products", {
                    gridAdd: false,
                    gridDelete: false,
                })

                this.setField("psm_relation_services", {
                    gridAdd: false,
                    gridDelete: false,
                })

                this.setField("psm_recommended_services", {
                    gridAdd: false,
                    gridDelete: false,
                })


                // setInterval(verificarURLYAtivarMostrarSomenteColunas, 500);

                // console.log("this ::>", this)
            }
        }


        if (this.globalProps.match.url.includes('/add') !== true && this.globalProps.values.psm_status_fk_situation_id == '11') {
            this.setField("psm_approve_date", { label: "Data da Reprovação" })
        }

        axios
            .get(`/api/v1/psm/observation/`, {
                params: { psm_id: this.globalProps.values.psm_id }
            })
            .then((res) => {
                if (res.status == 200) {
                    if ([null, undefined].includes(this.globalProps.values.psm_observations_logistics)) {
                        this.setState('loadings', 'psm_observations_logistics');

                        const dataReturn = res.data
                        this.setFieldValue("psm_observations_logistics", dataReturn.data[0].psm_obs_invoice)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState('loadings', []);
            });



        const isProspect = this.globalProps.values.client?.person_type_is_prospect !== true ? false : true;

        if ([true].includes(isProspect)) {
            this.setFieldValue('psm_client_is_prospect', true)
        } else {
            this.setFieldValue('psm_client_is_prospect', false)
        }

        // console.log("valor do this.globalProps", this.globalProps)
        if ([10, 11, 12, 17].includes(this.globalProps.values.psm_status_fk_situation_id)) {
            this.setField('psm_inclusion_product_compileds', {
                gridDelete: false,
                gridAdd: false
            })
        } else {

            if (this.globalProps.values.psm_status_fk_situation_id !== 5) {


                this.setField('psm_inclusion_product_compileds', {
                    gridDelete: false,
                    gridAdd: false,
                    gridEdit: false
                })

            }
        }

        const dadosPsm = this.globalProps.values;
        requiredColumns(this.globalProps.values.psm_status_fk_situation_id, this, 'psm')

        // console.log("Oi :::::::::::::::::::::: ",dadosPsm)

        this.setState("loadings", [
            "psm_fk_hub",
            "psm_fk_macro_region_id",
            "psm_microrregiao",
            "psm_fk_activity_sector_id",
            "psm_commercial_cell",
            "psm_sector_commercial",
            "psm_internal",
            "psm_external",
            "psm_analisys_hour_budget",
            "psm_commercial_fk_person_contact_id",
            "psm_carriers_fk_person_id",
            "psm_fk_payment_term_id",
            "psm_keyacoount",
            // "psm_deadline_analysis",
            "psm_final_date_delivery",
            "psm_conclusion_hours_finish"
        ]);

        // console.log("Res.data", this.globalProps)

        if (dadosPsm?.psm_id) {
            axios.get(`/api/v1/labs/${dadosPsm?.psm_id}`)
                .then((res) => {
                    if (res.data.item) {
                        // this.setFieldValue("psm_deadline_analysis", res.data.item.psm_deadline_analysis)
                        this.setFieldValue("psm_analisys_hour_budget", res.data?.item?.psm_analisys_hour)
                    }

                })
        }


        // let variavelName = dadosPsm.psm_clients_fk_person_id != null ? "psm_clients_fk_person_id" : "psm_fk_person_id"

        if (dadosPsm?.psm_commercial_fk_person_contact_id) {
            axios
                .get("/api/v1/person-contacts/" + dadosPsm?.psm_commercial_fk_person_contact_id)
                .then((res) => {
                    var data = res.data;
                    // console.log("valor do data :>", data)
                    if (data) {
                        axios
                            .get("/api/v1/person-contacts/" + data.item.person_contact_id)
                            .then((res) => {
                                var data = res.data;
                                if (data) {
                                    // console.log({ data });
                                    axios.get("/api/v1/sector/" + data.item.person_contact_fk_sector_id)
                                        .then((sector) => {
                                            if (sector) {
                                                this.setFieldValue("psm_sector_commercial", sector.data.item.sector_description)
                                            }
                                            // console.log("res", res)
                                        })
                                    this.setFieldValue("psm_phone_products", data.item.person_contact_phone);
                                    this.setFieldValue("psm_mail_products", data.item.person_contact_mail);
                                    this.setFieldValue("psm_cell_products", data.item.person_contact_cellphone);
                                    this.setFieldValue("psm_branch_products", data.item.person_contact_ramal);
                                    // setField("psm_contact_phone", data.item.person_district);
                                    // setField("estimete_contact_email", data.item.city.city_name);
                                }
                            })
                            .catch((e) => { })
                            .finally(() => {
                                // this.setState("loadings", []);
                            });
                        if (data.item.sector) {
                            this.setFieldValue("psm_sector_commercial", data.item.sector.sector_description);
                        } else {
                            this.setFieldValue("psm_sector_commercial", 'NENHUM');
                        }
                        this.setFieldValue("psm_commercial_phone", data.item.person_contact_phone);
                        this.setFieldValue("psm_commercial_email", data.item.person_contact_mail);
                        this.setFieldValue("psm_commercial_branch", data.item.person_contact_ramal);
                        this.setFieldValue("psm_commercial_cell", data.item.person_contact_cellphone);

                        // setField("psm_contact_phone", data.item.person_district);
                        // setField("estimete_contact_email", data.item.city.city_name);
                    }
                })
                .catch((e) => { })
                .finally(() => {

                });

        }

        // this.setFieldValue("psm_fk_macro_region_id", '');
        // this.setFieldValue("psm_fk_activity_sector_id", '');


        axios.get(`/api/v1/people/${dadosPsm?.psm_clients_fk_person_id}`)
            .then((res) => {
                if ((dadosPsm.psm_carriers_fk_person_id == undefined) && (dadosPsm.psm_carriers_fk_person_id == null)) {
                    if (res.data.item.person_shipping_fk_person_id !== null) {
                        this.setFieldValue("psm_carriers_fk_person_id", res.data.item.person_shipping_fk_person_id)
                    }
                }
                if (dadosPsm.psm_fk_payment_term_id == null) {
                    if (res.data.item?.person_fk_payment_term_id !== null) {
                        this.setFieldValue("psm_fk_payment_term_id", res.data.item?.person_fk_payment_term_id);
                    }
                }
                this.setFieldValue("psm_datebase_cnpj", res.data.item.person_cnpj_cpf)
                this.setFieldValue("psm_fk_hub", res.data.item.person_fk_macro_region_id);
                // if(dadosPsm.psm_microrregiao == null){
                this.setFieldValue("psm_microrregiao", res.data.item.person_microregion);
                // }
                this.setFieldValue("psm_fk_activity_sector_id", res.data.item.activity_sector.activity_sector_id);
                this.setFieldValue("psm_commercial_cell", res.data.item.person_contact_cellphone);
                if (dadosPsm?.psm_email_send_nfs == null) {
                    this.setFieldValue("psm_email_send_nfs", res.data.item.person_email_nfs);
                }
                this.setFieldValue("psm_partial_billing", res.data.item.person_accept_income);
                this.setFieldValue("psm_receiving_limit_nfse", res.data.item.person_get_nfse);
                this.setFieldValue("psm_measuring_sheet_frs", res.data.item.person_use_frs);

                this.setFieldValue("psm_obs_accept_income", res.data.item.person_obs_accept_income);
                this.setFieldValue("psm_obs_receiving_limit", res.data.item.person_obs_get_nfse);
                this.setFieldValue("psm_obs_measuring_sheet", res.data.item.person_obs_use_frs);

                this.setFieldValue("psm_fk_macro_region_id", '');
                this.setFieldValue("psm_fk_activity_sector_id", '');
                if (res.data.item.person_fk_macro_region_id) {
                    this.setFieldValue("psm_fk_macro_region_id", res.data.item.person_fk_macro_region_id);
                }
                this.setFieldValue("psm_database_citie", res.data.item.city.city_name)
                axios.get('/api/v1/states/' + res.data.item.city.city_fk_state_id).then((resState) => {
                    this.setFieldValue('psm_states_uf', resState.data.item.state_abbreviation)
                }).catch((e) => {
                    console.log(e)
                })

                // console.log("people", res.data.item)
                // this.setFieldValue("psm_carriers_fk_person_id", res.data.item.person_shipping_company)

                var usersSellers = res.data.item.person_sellers;

                // for (let i in usersSellers) {
                //     let person = usersSellers[i].seller

                //     if (person.seller_is_extern == true) {
                //         this.setFieldValue("psm_external", person.seller_is_extern == true ? person.seller_name : "Não existe vendedor Externo");
                //         continue;
                //     }
                // }
                // for (let i in usersSellers) {
                //     let person = usersSellers[i].seller
                //     this.setFieldValue("psm_internal", person.seller_is_extern == false ? person.seller_name : "Não Existe Vendedor Interno");
                //     this.setFieldValue("psm_from_fk_seller_id", person.seller_id);
                //     continue;
                // }
                if (dadosPsm.psm_clients_fk_person_id !== null) {
                    this.setFieldValue("psm_internal", '');
                    this.setFieldValue("psm_external", '');
                    this.setFieldValue("psm_keyacoount", '');
                    for (var k in res.data.item.person_sellers) {

                        if (res.data.item.person_sellers[k].seller.seller_type_of_seller !== 'K') {
                            if (res.data.item.person_sellers[k].seller.seller_is_extern == false) {
                                this.setFieldValue("psm_internal", res.data.item.person_sellers[k].seller.seller_name);
                                if (dadosPsm.psm_from_fk_seller_id == null) {
                                    this.setFieldValue("psm_from_fk_seller_id", res.data.item.person_sellers[k].seller.seller_id);
                                }
                            }
                            if (res.data.item.person_sellers[k].seller.seller_is_extern == true) {
                                this.setFieldValue("psm_external", res.data.item.person_sellers[k].seller.seller_name);
                            }
                        }
                        if (res.data.item.person_sellers[k].seller.seller_type_of_seller === 'K') {
                            this.setFieldValue("psm_keyacoount", res.data.item.person_sellers[k].seller.seller_name);
                        }
                    }
                }
                // var shippingCompany = dadosPsm.psm_shipping_company_fk_person_id;
                // if(shippingCompany === null){
                //     this.setFieldValue("psm_carriers_fk_person_id", res.data.item.psm_shipping_company_fk_person_id)
                // }else{

                //     this.setFieldValue("psm_carriers_fk_person_id", dadosPsm.psm_carriers_fk_person_id)
                // }


            })
            .catch((e) => { })
            .finally(() => {
                setTimeout(() => {
                    this.setState("loadings", []);
                }, 10000)
            });

        // this.setFieldValue('psm_obs_internal', "Entrei aqui")

        if (dadosPsm.psm_checked_propositure == null) {
            this.setField('psm_checked_propositure', {
                valueFieldCheck: true
            })
        }
        if (dadosPsm.psm_warranty_text == null) {
            this.setFieldValue("psm_warranty_text", "A MXDRIVE garante por três meses os serviços, as peças e componentes utilizados no serviço descrito e realizados em seu laboratório, havendo a necessidade de nova intervenção o mesmo deverá ser realizado no laboratório, ficando a MXDRIVE isenta de qualquer despesa com transporte, assistência técnica e outros custos. A garantia se aplica ao equipamento reparado isentando a MXDRIVE por danos e perdas causados a terceiros ou outros equipamentos.")
        }

        if (dadosPsm.psm_taxes == null) {
            this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS JÁ ESTÃO INCLUSOS.")
        }
        if (dadosPsm.psm_proposal_due_date == null) {
            this.setFieldValue("psm_proposal_due_date", "10 DIAS")
        }
        // this.setFieldValue("psm_payment_condition", dadosPsm.payment_term.payment_term_id)
        // this.setFieldValue("psm_from_fk_seller_id", { value: 33, label: "VENDEDOR" });

        var budgetClient = dadosPsm.psm_clients_fk_person_id;
        this.globalProps.values.psm_clients_fk_person_id = budgetClient;
        // if (budgetClient === null) {
        //     this.setFieldValue("psm_clients_fk_person_id", dadosPsm.psm_fk_person_id)
        //     this.setFieldValue("psm_datebase_cnpj", dadosPsm.psm_person_cnpj_cpf);
        //     this.setFieldValue("psm_database_citie", dadosPsm.psm_person_city);
        //     this.setFieldValue("psm_states_uf", dadosPsm.psm_person_state);
        //     // this.setFieldValue("psm_analisys_hour_budget", dadosPsm.psm_analisys_hour);
        // }


        //transportadora
        var shippingCompany = dadosPsm.psm_shipping_company_fk_person_id;
        // console.log("campo", dadosPsm.psm_carriers_fk_person_id)
        // console.log("neymar", this.globalProps.values)

        // this.globalProps.values.psm_carriers_fk_person_id = shippingCompany;


        //frete
        // var shipmentType = dadosPsm.type_frete?.type_frete_id;
        // this.globalProps.values.psm_shipment_fk_type_frete_id = shipmentType;
        // if(shipmentType === null){
        //     this.setFieldValue("psm_shipment_fk_type_frete_id", 2)
        // }
        // this.setFieldValue("psm_shipment_fk_type_frete_id", dadosPsm.type_frete?.type_frete_id)

        if (dadosPsm.psm_shipment_fk_type_frete_id == null) {
            this.setFieldValue("psm_shipment_fk_type_frete_id", 4)
        }

        this.setFieldValue("psm_fk_activity", dadosPsm.person?.person_fk_activity_sector_id)
        // console.log("SETOR::::", dadosPsm.person)

        // this.setFieldValue("psm_refer_equipment_new", dadosPsm.psm_new_equipment_value * dadosPsm.psm_final_value)

        var valorHoraServico = dadosPsm.psm_hour_value
        if (valorHoraServico === null) {
            this.setFieldValue("psm_hour_value", 250)
        }

        // var vendedorExterno = dadosPsm.seller?.seller_is_extern;
        // if (vendedorExterno === false) {
        //     this.setFieldValue("psm_internal", dadosPsm.seller?.seller_name);
        //     this.setFieldValue("psm_external", null);
        // } else {
        //     this.setFieldValue("psm_internal", null);
        //     this.setFieldValue("psm_external", dadosPsm.seller?.seller_name);
        // }

        // var tipoKeyAccount = dadosPsm.seller?.seller_type_of_seller;
        // if (tipoKeyAccount === 'K') {
        //     this.setFieldValue("psm_keyacoount", dadosPsm.seller?.seller_name);
        // }

        var potencyCurrent = dadosPsm.psm_potency;
        if (potencyCurrent !== null) {
            this.setFieldValue("psm_potency_current", dadosPsm.psm_potency)
        }
        if (potencyCurrent === null) {
            this.setFieldValue("psm_potency_current", dadosPsm.psm_current)
        }

        // console.log("dados da Psm", dadosPsm)

        this.setFieldValue(
            "psm_conclusion_hours_finish",
            dadosPsm.psm_conclusion_hours_finish == undefined
                || dadosPsm.psm_conclusion_hours_finish == null
                ? dadosPsm.psm_conclusion_hours_to_finish
                : dadosPsm.psm_conclusion_hours_finish
        );

        this.setFieldValue("psm_conclusion_hour_to_finish", dadosPsm.psm_conclusion_hours_finish)
        this.setFieldValue("psm_minimum_value", dadosPsm.psm_conclusion_hours_to_finish * 180)
        this.setFieldValue("psm_conclusion_warranty_period", dadosPsm.psm_warranty_period)
        this.setFieldValue("psm_conclusion_warranty_requested", dadosPsm.psm_warranty_requested)

        if (dadosPsm.psm_considered_value == null) {
            this.setFieldValue("psm_considered_value", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))
        }
        if (dadosPsm.psm_final_value == null) {
            this.setFieldValue("psm_final_value", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))
        }
        if (dadosPsm.psm_total_product_service == null) {
            this.setFieldValue("psm_total_product_service", (dadosPsm.psm_conclusion_hours_to_finish * dadosPsm.psm_hour_value))

        }

        // console.log({dadosPsm});
        // axios
        //     .get(`/api/v1/payment_terms/${dadosPsm.psm_fk_payment_term_id}`)
        //     .then((res) => {
        //         this.setFieldValue('psm_billing_type', res.data.item.payment_term_name);


        //     })
        //     .finally(() => { })

        if (dadosPsm.psm_purpose_product !== null) {
            this.setFieldValue('psm_billing_type', dadosPsm.psm_purpose_product == 1 ?
                'USO E CONSUMO' : dadosPsm.psm_purpose_product == 2 ? 'REVENDA' : 'INDUSTRIALIZAÇÃO');
        }
        if (dadosPsm.psm_commercial_fk_person_contact_id == null) {
            if (dadosPsm.psm_clients_fk_person_id) {
                this.setFieldProperty('psm_commercial_fk_person_contact_id', 'options', []);
                axios
                    .get(`/api/v1/commercial/person_contacts/${dadosPsm.psm_clients_fk_person_id}`)
                    .then((res) => {
                        this.setFieldProperty("psm_commercial_fk_person_contact_id", 'options', res.data.items)
                    })
            }
        }

        // console.log("Cheguei aqui :::::>")

        try {
            const maskDate = function (value: string) {
                if (value.includes('/')) {
                    value = value.split('/').join('')
                }
                let day = value.substring(0, 2);
                let month = value.substring(2, 4);
                let year = value.substring(4, 8);
                return `${day}-${month}-${year}`
            }

            if (dadosPsm.psm_purpose_product !== null) {
                this.setFieldValue('psm_billing_type', dadosPsm.psm_purpose_product == 1 ?
                    'USO E CONSUMO' : dadosPsm.psm_purpose_product == 2 ? 'REVENDA' : 'INDUSTRIALIZAÇÃO');
            }
            // console.log('Entrei aqui ::: ', dadosPsm.psm_final_date_delivery)

            if (dadosPsm.psm_status_fk_situation_id == 11) {
                this.setFieldValue("psm_final_date_delivery", '');
                this.setFieldValue('psm_lead_time', '');
            } else {
                if (![null, undefined, ''].includes(dadosPsm.psm_final_date_delivery)) {
                    let dateEntrie = dayjs(maskDate(dadosPsm.psm_approve_date), 'DD/MM/YYYY').format('YYYY-MM-DD');
                    let getDate = function (dataInicial: any, deadline: any) {
                        let days = deadline
                        let ignoredDays = [6, 0]
                        let initialDate: any = dayjs(dataInicial, 'YYYY-MM-DD').format("MM-DD-YYYY")
                        do {
                            // console.log("I'm here")
                            let getWeekDay = dayjs(initialDate).get("day")
                            // console.log('day week ::: ',{initialDate}, getWeekDay);

                            if (!ignoredDays.includes(getWeekDay)) {
                                days = days - 1
                            }
                            initialDate = dayjs(initialDate).add(1, 'day')
                        }
                        while (days > 0);
                        let verification: any = dayjs(initialDate).get("day")
                        if ([6].includes(verification)) {
                            initialDate = dayjs(initialDate).add(2, 'day')
                        }
                        if (verification == 0) {
                            initialDate = dayjs(initialDate).add(1, 'day')
                        }
                        return dayjs(initialDate).format('DD/MM/YYYY');
                    }
                    // console.log("Estou aqui ::::>>>>>>>", dadosPsm)
                    // this.setFieldValue("psm_final_date_delivery", getDate(dateEntrie, dadosPsm.psm_deadline))
                }

                if (![null, undefined, ''].includes(dadosPsm.psm_final_date_delivery)) {
                    let dateNow: any = dayjs().format("YYYY-MM-DD");
                    let dateDelivery: any = dadosPsm.psm_final_date_delivery;
                    dateDelivery = dayjs(dateDelivery, 'YYYY-MM-DD').format('YYYY-MM-DD')
                    let diffDate = dayjs(dateDelivery, "YYYY-MM-DD").diff(dateNow, 'day');
                    let resultDiff = function (dateDiff: any) {
                        // console.log("Entrei aqui :::>O*&", dateDelivery)
                        if (dateDiff == 0) {
                            return "Hoje é o dia da Entrega";
                        }
                        if (Math.sign(dateDiff) == 1) {
                            return `Faltam ${dayjs(dateDelivery, "YYYY-MM-DD").diff(dateNow, 'day')} dias`
                        } else {
                            return `Passaram ${dayjs(dateDelivery, "YYYY-MM-DD").diff(dateNow, 'day')} dias`
                        }
                    }
                    // this.setFieldValue("psm_final_date_delivery", dadosPsm.psm_final_date_delivery)
                    this.setFieldValue("psm_lead_time", dadosPsm.psm_status_fk_situation_id !== 16 ? resultDiff(diffDate) : 'PSM Finalizada');
                }
            }



        } catch (e) { console.log('error ::: ', e) }

    }

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        // console.log("Tessssssssssssssssssssssssssssssssssssssst");
    }


}
export class ApprovalEvents extends Event {


    public onLoad = async (value: any) => {


        // console.log("entrei aqui :>", this.globalProps.values)
        // const values = this.globalProps.values;

        // if (values?.psm_approve_date) {
        //     const dataCorretaApprove = await LabEvents.converterData(this.globalProps.values.psm_approve_date);
        //     this.setFieldValue("psm_approve_date", '')


        //     if (dataCorretaApprove !== null) {
        //         this.setFieldValue("psm_approve_date", dataCorretaApprove)
        //     }

        // }

        // if (values?.psm_final_date_delivery) {
        //     console.log("psm_final_date_delivery :.", values.psm_final_date_delivery)
        //     const dataCorretaDelivery = await LabEvents.converterData(this.globalProps.values.psm_final_date_delivery);
        //     this.setFieldValue("psm_final_date_delivery", '')


        //     if (dataCorretaDelivery !== null) {
        //         this.setFieldValue("psm_final_date_delivery", dataCorretaDelivery)
        //     }

        // }

        // if (values?.psm_final_delivery_change) {

        //     const dataCorretaDeliveryChange = await LabEvents.converterData(this.globalProps.values.psm_final_delivery_change);
        //     this.setFieldValue("psm_final_delivery_change", '')
        //     // console.log("dataCorretaDeliveryChange :>", dataCorretaDeliveryChange)

        //     if (dataCorretaDeliveryChange !== null) {
        //         this.setFieldValue("psm_final_delivery_change", dataCorretaDeliveryChange)
        //     }


        // }
    }
}