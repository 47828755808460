import axios from "../../utilities/axios";
import { Event } from "../Event";

export class BoxAllocatedPackageEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        console.log('Value :::::::::::::::::::::::::::',value)
        await this.setState("loadings",[
            "box_inclusions",
        ]);
        this.setFieldValue("box_inclusions", value);    
        await this.setState("loadings",[]);


        this.setState("loadings",[
            "box_fk_person_id",
            "box_cnpj_cpf",
            "box_person_city",
            "box_person_state",
            "box_seller_internal",
            "box_seller_external",
            "box_fk_type_frete_id",
            "box_shippment_fk_person_id",
            "box_redispatch_fk_person_id",
            "box_romanian"
        ]);

        //redespacho //

        
        let redispath:any = '';
        let validateRedispath:any
        for(var i in value){
            if(redispath !== ''){
                validateRedispath = value[i].psm_resend_fk_person_id !== redispath ? false : true    
            }
            if(validateRedispath == false){
                continue
            }
            redispath = value[i].psm_resend_fk_person_id
        }
        // console.log(validateRedispath)
        console.log({redispath});
        if(value.length >= 2){
            axios
            .get('/api/v1/packing/people/person_fantasy', {params: {
                value: value[0].psm_resend_fk_person_id,
                columnSelect: 'person_id'
            }})
            .then((res) => {
                this.setState("loadings",[
                    "box_redispatch_fk_person_id"
                ])
                if(res.data.status == 200){
                    // console.log('res ::::::: ',res.data)
                    this.setFieldValue("box_redispatch_fk_person_id", res.data?.response?.person_id);
                    this.setState("loadings", [])
                }
            })
            if(validateRedispath == true){
                this.setFieldValue("box_redispatch_fk_person_id", value[0].psm_resend_fk_person_id)
            }else {
                this.setField("box_redispatch_fk_person_id", {readonly: false})
            }
        }else{
            axios
            .get('/api/v1/packing/people/person_fantasy', {params: {
                value: value[0].psm_resend_fk_person_id,
                columnSelect: 'person_id'
            }})
            .then((res) => {
                this.setState("loadings",[
                    "box_redispatch_fk_person_id"
                ])
                if(res.data.status == 200){
                    // console.log('res ::::::: ',res.data)
                    this.setFieldValue("box_redispatch_fk_person_id", res.data?.response?.person_id);
                    this.setState("loadings", [])
                }
            })
            // console.log("redispath ::::::::::::",value[0].psm_resend_fk_person_id);
        }

        if(redispath == ""){
            this.setField("box_redispatch_fk_person_id", {readonly: false});
        }

        // Transportadora //
        let transport:any = '';
        let validateTransport:any
        for(var i in value){
            if(transport !== ''){
                validateTransport = value[i].psm_shipping_code !== transport ? false : true    
            }
            if(validateTransport == false){
                continue
            }
            transport = value[i].psm_shipping_code
        }
        // console.log(validateTransport)
        // console.log({transport});
        if(value[0].psm_shipping_code !== null){
            if(value.length >= 2){
                validateTransport == true ? this.setFieldValue("box_shippment_fk_person_id", value[0].psm_shipping_code) : this.setField("box_shippment_fk_person_id", {readonly: false})
    
            }else{
                this.setFieldValue("box_shippment_fk_person_id", value[0].psm_shipping_code);
                this.setState("loadings", [
                    "box_cnpj_transport", "box_city_transport"
                ]);

                axios
                    .get(`/api/v1/psm/getcarrierinfos/${value[0].psm_shipping_code}`)
                    .then((res) => {
                        const infos = res.data.infos
                        this.setFieldValue("box_cnpj_transport", infos.cnpj)
                        this.setFieldValue("box_city_transport", infos.city)
                    })

                setTimeout(() => {
                    this.setState("loadings", []);
                }, 2000)
            }
        }

        if(validateTransport == false){
            this.setFieldValue('box_shippment_fk_person_id', )
        }

        this.setFieldValue("box_cnpj_cpf", value[0].box_inclusion_cnpj);
        this.setFieldValue("box_person_city", value[0].box_inclusion_city);
        this.setFieldValue("box_person_state", value[0].box_inclusion_state);
        this.setFieldValue("box_business_unity", value[0].box_inclusion_company);

        await axios
            .get(`/api/v1/boxes/next-code/${value[0].box_inclusion_company}`)
            .then((res) => {
                if(res.data.status == 200){
                    this.setFieldValue("box_romanian", res.data.code);
                    this.setFieldValue('box_internal_code',res.data.sequencial);
                }
            }).catch((e) => {console.log(e)})

        let personId = null;
        await axios
            .get('/api/v1/packing/people/person_cnpj_cpf', {params: {
                value: value[0].box_inclusion_cnpj,
                columnSelect: 'person_id'
            }})
            .then((res) => {
                this.setState("loadings",[
                    "box_fk_person_id"
                ])
                if(res.data.status == 200){
                    personId = res.data.response.person_id
                    // console.log("teste ::: ", res.data.response);
                    this.setFieldValue("box_fk_person_id", res.data.response.person_id);
                    this.setState("loadings", [])
                }
            })
    
        axios
        .get("/api/v1/people/" + personId)
        .then((res) => {
            var data = res.data;
            var usersSellers = data.item.person_sellers;
            if(data){
                this.setState('loadings',[
                    "box_seller_external",
                    "box_seller_internal"
                ])
                for(let i in usersSellers){
                    let person = usersSellers[i].seller

                    if(person.seller_is_extern == true){
                        this.setFieldValue("box_seller_external", person.seller_is_extern == true ? person.seller_name : "Teste");
                        continue;
                    }
                }
                for(let i in usersSellers){
                    let person = usersSellers[i].seller
                    this.setFieldValue("box_seller_internal", person.seller_is_extern == false ? person.seller_name : "Não Existe Vendedor Interno");
                    continue;
                }

                this.setState('loadings',[])
            }
        });

        // this.setState("loadings",[
        //                 "box_fk_type_frete_id"
        //             ])

        // this.setFieldValue("box_fk_type_frete_id",value[0].psm_shipment_type_fk_type_frete_id);
        // console.log("Valor do value", value[0])
        axios
        .get('/api/v1/shipping/' + value[0].box_inclusion_id_psm)
        .then((res) => {
            this.setState("loadings",[
                "box_fk_type_frete_id"
            ])
            if(res.data.status == 200){
                console.log("teste ::: ", res.data.response);
                if(res.data.response !== undefined){
                    this.setFieldValue("box_fk_type_frete_id", res.data.response.psm_shipment_fk_type_frete_id
                    );
                }
                this.setState("loadings", [])
            }
        })
        // axios
        //     .get('/api/v1/packing/type_fretes/type_frete_name', {params: {
        //         value: value[0].box_inclusion_shipment_type,
        //         columnSelect: 'person_id'
        //     }}) 
        //     .then((res) => {
        //         this.setState("loadings",[
        //             "box_fk_type_frete_id"
        //         ])
        //         if(res.data.status == 200){
        //             console.log("teste ::: ", res.data.response);
        //             if(res.data.response !== undefined){
        //                 this.setFieldValue("box_fk_type_frete_id", res.data.response.type_frete_id);
        //             }
        //             this.setState("loadings", [])
        //         }
        //     })

        // axios
        // .get('/api/v1/packing/companies/company_name', {params: {
        //     value: value[0].box_inclusion_company,
        // }})
        // .then((res) => {
        //     this.setState("loadings",[
        //         "box_business_unity"
        //     ])
        //     if(res.data.status == 200){
        //         // console.log("teste ::: ", res.data.response);
        //         this.setFieldValue("box_business_unity", res.data.response.company_id);
        //         this.setState("loadings", [])
        //     }
        // })

            // console.log("shipping ::: ", value[0].box_inclusion_shipping_company)
        
        // if(validateTransport == true) {
        //     axios
        //     .get('/api/v1/packing/people/person_fantasy', {params: {
        //         value: value[0].box_inclusion_shipping_company,
        //         columnSelect: 'person_id'
        //     }})
        //     .then((res) => {
        //         this.setState("loadings",[
        //             "box_shippment_fk_person_id"
        //         ])
        //         if(res.data.status == 200){
        //             // console.log("teste ::: ", res.data.response);
        //             this.setFieldValue("box_shippment_fk_person_id", res.data.response?.person_id);
        //             this.setState("loadings", [])
        //         }
        //     })
        // }
        this.setState("loadings",[]);
    }
}
