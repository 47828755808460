import _ from "lodash";
import axios from "../../utilities/axios";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { Event } from "../Event";
import dayjs from "dayjs";

export class ProductBoxEvents extends Event {

    // static async converterData(dataAmericana: string) {
    //     if (dataAmericana && dataAmericana.length > 8) {

    //         const partes = dataAmericana?.split('-');
    //         const splitData = partes[2]?.split('T');

    //         const mes = partes[1];
    //         const dia = splitData[0];
    //         const ano = partes[0];
    //         const dataBrasileira = `${dia}/${mes}/${ano}`;
    //         return dataBrasileira;
    //     }
    //     if (dataAmericana && dataAmericana.length == 8) {
    //         console.log("dataAmericana :>", dataAmericana)

    //         const mes = dataAmericana.substring(4, 6);
    //         const dia = dataAmericana.substring(6, 8);
    //         const ano = dataAmericana.substring(0, 4);
    //         const dataBrasileira = `${dia}/${mes}/${ano}`;
    //         return dataBrasileira;
    //     }
    // }

    public onInit = () => {
        if (!this.globalProps.location.pathname.includes('/view')) {
            axios
                .get("/api/v1/me")
                .then((subRes) => {
                    var subData = subRes.data;
                    //console.log(subData);
                    if (subData) {
                        this.setFieldValue("product_box_user", subData.user.user_name);
                    }
                })
                .catch((error) => { })
                .finally(() => {
                    const loadings = [...this.globalState.loadings];
                    loadings.splice(loadings.indexOf("psm_user"));
                    this.setState({ loadings });
                });
            this.setFieldValue("product_box_date_romanian", dayjs().subtract(3, 'hour').format('YYYY-MM-DD HH:mm:ss'));
            this.setFieldValue("product_box_volume", 1);
            this.setFieldValue("product_box_fk_box_situation_id", 1);
        }
    }

    public onLoad = async () => {
        requiredColumns(this.globalProps.values.product_box_fk_box_situation_id, this, 'product_box');
        if (this.globalProps.values.box_fk_box_situation_id > 1) {
            this.setField('product_box_allocated_package', {
                hidden: true
            })
        }

        if (!this.globalProps.match.url.includes('/add')) {
            this.setFieldValue("product_box_user", this.globalProps.values?.product_box_user.toLowerCase())

            this.setState("loadings", [
                "product_box_cnpj_transport", "product_box_city_transport"
            ]);

            if (this.globalProps.values.product_box_shippment_fk_person_id) {
                axios
                    .get(`/api/v1/psm/getcarrierinfos/${this.globalProps.values.product_box_shippment_fk_person_id}`)
                    .then((res) => {
                        const infos = res.data.infos
                        this.setFieldValue("product_box_cnpj_transport", infos.cnpj)
                        this.setFieldValue("product_box_city_transport", infos.city)
                    })
            }





            // if (this.globalProps.values.product_box_issue_nf) {

            //     const dataCorreta = await ProductBoxEvents.converterData(this.globalProps.values.product_box_issue_nf);
            //     this.setFieldValue("product_box_issue_nf", '')

            //     if (dataCorreta !== null) {
            //         this.setFieldValue("product_box_issue_nf", dataCorreta)
            //     }
            // }

            // if (this.globalProps.values.product_box_collect_date) {

            //     const dataCorreta = await ProductBoxEvents.converterData(this.globalProps.values.product_box_collect_date);
            //     this.setFieldValue("product_box_collect_date", '')

            //     if (dataCorreta !== null) {
            //         this.setFieldValue("product_box_collect_date", dataCorreta)
            //     }
            // }



            this.setState("loadings", []);
        }
        // if (!this.globalProps.location.pathname.includes('/add')) {
        //     if(this.globalProps.values.product_box_fk_box_situation_id > 1)
        //     this.setField('product_box_inclusions', {
        //         gridDelete: false,
        //         gridView: true
        //     })
        // }
        // requiredColumns(this.globalProps.values.box_fk_box_situation_id,this,'product_box')
    }

}