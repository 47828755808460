import React from "react";
import { UserContext } from "./authProvider/main";

export class Permission extends React.Component<any, {}> {
    static contextType = UserContext;

    static canUse(key: string, user: any) {
        if (user?.role?.role_permissions) {
            // alert(user?.role?.role_id);
            if (user?.role?.role_id === 2) {
                return true;
            }
            var perms: string[] = user?.role?.role_permissions.map((item: any) => {
                return item.role_permission_value;
            })
            for (var index in perms) {
                var perm = perms[index].split('-').join('_');
                if (!perm.includes('-')) {
                    perms = [...perms, perm + '-browse', perm + '-read', perm + '-edit', perm + '-add', perm + '-delete', perm + '-extract', perm + '-viewfields']
                }
            }
            // //({perms});
            return perms.includes(key);
        }
        return false;
    }

    render() {
        if (this.props.name) {
            if (Permission.canUse(this.props.name ?? '', this.context.data)) {
                return this.props.children;
            }
            return null;

        }
        return this.props.children;

    }
}